import '@/utils/initDevtools';
import '@/style/index.sass';
import 'focus-visible';

import { getRouter, getStore, initBuefy, initFirebase } from '@caresend/ui-components';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import {
  faAlignLeft,
  faArrowUp,
  faBan,
  faBolt,
  faClipboardCheck,
  faCode,
  faCompress,
  faExpand,
  faFileExport,
  faLanguage,
  faList,
  faLockOpen,
  faMapMarkedAlt,
  faParachuteBox,
  faPaste,
  faSave,
  faSyringe,
  faTasks,
  faTools,
} from '@fortawesome/sharp-regular-svg-icons';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import { PiniaVuePlugin, createPinia } from 'pinia';
import Vue from 'vue';
import VueMobileDetection from 'vue-mobile-detection';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import App from '@/App.vue';
import '@/registerServiceWorker';
import { initSegment, initializeSentry } from '@/functions/tracking';
import { initRouter } from '@/router';
import { initStore } from '@/store';

initFirebase();
initializeSentry();
initSegment();
initStore();
initRouter();

dayjs.extend(advancedFormat);
dayjs.extend(calendar);
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekday);

// TODO: determine why the import of VuePhoneNumberInput in this file
// is critical to a successful build, solve, then remove.
Vue.component('VuePhoneNumberInput', VuePhoneNumberInput);

Vue.use(VueMobileDetection);

const faSharpRegularAdditionalIcons = [
  faAlignLeft,
  faArrowUp,
  faBan,
  faBolt,
  faClipboardCheck,
  faCode,
  faCompress,
  faExpand,
  faFileExport,
  faLanguage,
  faList,
  faLockOpen,
  faMapMarkedAlt,
  faParachuteBox,
  faPaste,
  faSave,
  faSyringe,
  faTasks,
  faTools,
];

/**
 * TODO: Only icons in the sharp, regular style are currently supported. Test
 * whether usages of this brand icon still work. If not, changes may be needed
 * in `Icon.vue` in ui-components.
 */
const faBrandIcons = [
  faGoogle,
];

const faAdditionalIcons = [
  ...faSharpRegularAdditionalIcons,
  ...faBrandIcons,
];

initBuefy(Vue, faAdditionalIcons);

Vue.config.productionTip = false;
Vue.use(PiniaVuePlugin);
const pinia = createPinia();

const initApp = async () => {
  await getStore().dispatch('app/initApp');
  initRouter();

  new Vue({
    name: 'Dashboard',
    router: getRouter(),
    store: getStore(),
    pinia,
    render: (h) => h(App),
  }).$mount('#app');
};

initApp();
